import { useSelector } from 'react-redux';
import SnackbarProvider from 'hooks/global-snack';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import Routes from 'routes';

import themes from 'themes';

import NavigationScroll from 'layout/NavigationScroll';
import { AuthProvider } from 'hooks/useAuth';

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <SnackbarProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  );
};

export default App;
