import CookieService from 'services/cookie.service';

export function getDefaultFilter() {
  let service = new CookieService();
  return service.getCookie('filterId');
}

const initialState = {
  filterId: getDefaultFilter()
};

export const setNewFilter = (filterId) => {
  return {
    type: 'SET_FILTER',
    payload: filterId
  };
};

function filterReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_FILTER':
      return {
        ...state,
        filterId: action.payload
      };
    default:
      return state;
  }
}

export default filterReducer;
