import { Alert, Slide, Snackbar } from '@mui/material';
import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
const SnackbarContext = createContext();

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

const SnackbarProvider = ({ children }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [snackbarColor, setSnackbarColor] = useState('info');
  const [snackbarHideDuration, setSnackbarHideDuration] = useState(5000);

  const showSnackbar = useCallback((message = 'NO_MESSAGE', severity = 'info', autoHideDuration = 5000, color = null) => {
    if (color == null) color = severity;

    setSnackbarOpen(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarColor(color);
    setSnackbarHideDuration(autoHideDuration);
  }, []);

  const hideSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      <Snackbar autoHideDuration={snackbarHideDuration} open={snackbarOpen} onClose={hideSnackbar} TransitionComponent={Slide}>
        <Alert variant="filled" color={snackbarColor} onClose={hideSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.any
};

export default SnackbarProvider;
