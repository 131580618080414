// material-ui
// import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 *
 */

import logo from 'assets/images/burgerX-logo.png';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src={logo} alt="Berry" width="60" />;
};

export default Logo;
