import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { ProtectedRoute } from './utils/ProtectedRoute';

const Orders = Loadable(lazy(() => import('views/pages/orders')));
// const Config = Loadable(lazy(() => import('views/pages/config')));
const Sales = Loadable(lazy(() => import('views/pages/bi/sales')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Sales />
        </ProtectedRoute>
      )
    },
    {
      path: '/sales',
      element: (
        <ProtectedRoute>
          <Sales />
        </ProtectedRoute>
      )
    },
    {
      path: '/orders',
      element: (
        <ProtectedRoute>
          <Orders />
        </ProtectedRoute>
      )
    }
  ]
};

export default MainRoutes;
