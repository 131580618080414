import { IconChartHistogram, IconBurger, IconUser, IconHome, IconHeartbeat } from '@tabler/icons-react';

const catalog = {
  id: 'dash',
  title: 'Vendas',
  type: 'group',
  children: [
    {
      id: 'sales',
      url: '/sales',
      title: 'Geral',
      type: 'item',
      icon: IconChartHistogram,
      breadcrumbs: false
    },
    {
      id: 'product',
      url: '/sales/product',
      title: 'Produto',
      type: 'item',
      icon: IconBurger,
      breadcrumbs: false
    },
    {
      id: 'customer',
      url: '/sales/customer',
      title: 'Cliente',
      type: 'item',
      icon: IconUser,
      breadcrumbs: false
    },
    {
      id: 'address',
      url: '/sales/address',
      title: 'Endereço',
      type: 'item',
      icon: IconHome,
      breadcrumbs: false
    },
    {
      id: 'performance',
      url: '/sales/performance',
      title: 'Desempenho Geral',
      type: 'item',
      icon: IconHeartbeat,
      breadcrumbs: false
    }
  ]
};

export default catalog;
